import React from 'react';
import AdSense from 'react-adsense';

import Search from './search';
import AboutUs from './about-us';
import SubscribeAndFollow from './subscribe-and-follow';
import Categories from './categories';
import Archives from './archives';
import Tags from './tags';

const Sidebar = ({searchQuery, setSearchQuery, tags, categories, archives, hideSearch, hideAds}) => {

  return (
    <div className="sidebar">
      {!hideSearch && <Search searchQuery={searchQuery} setSearchQuery={setSearchQuery} />}
      {hideAds && <AdSense.Google className="ad-square" client='ca-pub-4286668524137813' slot='9042380064' format='auto' responsive='false' />}
      <AboutUs />
      <SubscribeAndFollow />
      {hideAds && <AdSense.Google className="ad-square" client='ca-pub-4286668524137813' slot='2176469253' format='auto' responsive='false' />}
      <Tags tags={tags} />
      {hideAds && <AdSense.Google className="ad-square" client='ca-pub-4286668524137813' slot='1698791258' format='auto' responsive='false' />}
      <Archives archives={archives} />
      {hideAds && <AdSense.Google className="ad-square" client='ca-pub-4286668524137813' slot='8974646047' format='auto' responsive='false' />}
      <Categories categories={categories} />
      {hideAds && <AdSense.Google className="ad-tall" client='ca-pub-4286668524137813' slot='7881056221' format='auto' responsive='false' />}
      {/* <RecentPosts /> */}
    </div>
  );
};

export default Sidebar;