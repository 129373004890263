import React from 'react';
import { Link, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import AdSense from 'react-adsense';

import LayoutPost from '../components/layout-post';
import Seo from '../components/seo';
import PostTags from '../components/post-tags';
import Bio from '../components/bio';
import PostPreviewSmall from '../components/post-preview-small';
import Comments from '../components/comments';

const BlogPostTemplate = ({ data, location, hideAds }) => {
  //console.log({ data, location });

  const post = data.markdownRemark;
  const siteTitle = data.site.siteMetadata?.title || `Title`;
  const { previous, next } = data;
  const tags = data.sidebarData.tags;
  const categories = data.sidebarData.categories;
  const archives = data.sidebarData.archives;
  const searchQuery = undefined;
  const setSearchQuery = undefined;
  const image = post.frontmatter.image ? post.frontmatter.image.childImageSharp.resize : null;

  const author = {
    name: post.frontmatter.author.name,
    websiteUrl: post.frontmatter.author.socials.website
  };

  const dates = {
    published: post.frontmatter.originalDate,
    modified: post.frontmatter.originalDate
  };

  //console.log(post);

  return (
    <LayoutPost
      location={location}
      title={siteTitle}
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
      tags={tags}
      categories={categories}
      archives={archives}
      hideSearch={true}>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        image={image}
        pathname={location.pathname}
        keywords={post.frontmatter.tags}
        addJsonLdMetadata={true}
        author={author}
        dates={dates} />
      <article className="post">
        <div className="post-header">
          <h1 className="post-title">{post.frontmatter.title}</h1>
          <ul className="post-meta">
            <li key={post.frontmatter.date}><i className="icon-calendar"></i> {post.frontmatter.date}</li>
            <li key="tags"><i className="icon-tags"></i>
              {post.frontmatter.tags.map((tag, index) => {
                return (index <= 2 && <Link to={`/tags/${tag}`} key={tag}>{` ${tag}, `}</Link>);
              })}
            </li>
            <li key={post.fields.readingTime.text}><i className="icon-clock"></i> {post.fields.readingTime.text}</li>
          </ul>
        </div>
        <div>
          {hideAds && <AdSense.Google className="ad-long" client='ca-pub-4286668524137813' slot='4756199436' format='auto' responsive='false' />}
        </div>
        <div className="post-preview">
          <GatsbyImage image={getImage(post.frontmatter.image)} alt={post.frontmatter.title} quality={80} layout="fixed" formats={["AUTO", "WEBP"]} />
        </div>

        <div className="blog-detail-description">
          <div className="blog-article" dangerouslySetInnerHTML={{ __html: post.html }}></div>

          <PostTags tags={post.frontmatter.tags} />
          <Bio author={post.frontmatter.author} />

          <div>
            <div className="mt-5 text-center">
              <h5 className="page-title-alt" style={{ paddingBottom: '20px' }}><span>You Might Also Like</span></h5>
            </div>
            <div className="row">
              {previous &&
                (<PostPreviewSmall slug={previous.fields.slug} title={previous.frontmatter.title} date={previous.frontmatter.date} image={previous.frontmatter.image} isNext={false} />)}
              <div className="col-sm-2"></div>
              {next &&
                (<PostPreviewSmall slug={next.fields.slug} title={next.frontmatter.title} date={next.frontmatter.date} image={next.frontmatter.image} isNext={true} />)}
            </div>
          </div>
          <div>
            {hideAds && <AdSense.Google className="ad-long" client='ca-pub-4286668524137813' slot='5353775868' format='auto' responsive='false' />}
          </div>
          <div className="mt-5">
            <h5 className="page-title-alt"><span>Comments</span></h5>
          </div>
          <Comments />
        </div>
      </article>
    </LayoutPost>
  )
}

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
    ) {
      site {
        siteMetadata {
          title
        }
      }
      markdownRemark(id: {eq: $id }) {
        id
        fields {
          slug
          readingTime {
            text
          }
        }
        html
        frontmatter {
          title
          date(formatString: "MMMM DD, YYYY")
          originalDate: date
          categories
          author {
            id
            name
            description
            profile: image {
              childImageSharp {
                gatsbyImageData(
                  width:78
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
            socials {
              facebook
              github
              twitter
              linkedin
              website
            }
          }
          description
          tags
          image: featured {
            childImageSharp {
              gatsbyImageData(
                width:1200
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
              resize(width: 1200) {
                src
                height
                width
              }
            }
          }
        }
      }
      previous: markdownRemark(id: {eq: $previousPostId }) {
        fields {
          slug
        }
        frontmatter {
          title
          date(formatString: "MMMM DD, YYYY")
          image: featured {
            childImageSharp {
              gatsbyImageData(
                width:600
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
      next: markdownRemark(id: {eq: $nextPostId }) {
        fields {
          slug
        }
        frontmatter {
          title
          date(formatString: "MMMM DD, YYYY")
          image: featured {
            childImageSharp {
              gatsbyImageData(
                width:600
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
      sidebarData: allMarkdownRemark(limit: 1000, sort: { fields: [frontmatter___date], order: DESC }) {
        tags: group(field: frontmatter___tags) {
          tag: fieldValue
          totalCount
        }
        categories: group(field: frontmatter___categories) {
          category: fieldValue
          totalCount
        }
        archives: group(field: frontmatter___archive) {
          archive: fieldValue
          totalCount
        }
      }
    }
`
