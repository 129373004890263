import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const AboutUs = () => {
  return (
    <aside className="widget about-widget">
      <div className="widget-title">About Us</div>
      <div className="text-center">
        <StaticImage
          className="rounded-circle"
          src="../images/k2v-logo.png"
          layout="fullWidth"
          placeholder="blurred"
          alt="About K2V Academy"
          formats={["auto", "webp"]}
          style={{ width: '150px', margin: 'auto' }} />
        <p>K2V Academy is dedicated to educating the community in Cloud Technologies, Software Development, DevOps, Security and many more. We focus on the Microsoft Technology stack and will be discussing technologies such as Microsoft Azure, Azure DevOps, .Net Core, ASP.Net Core, C#, TypeScript/JavaScript and topics related to DevOps, Security and Performance in the Microsoft technology space.</p>
      </div>
    </aside>
  );
};

export default AboutUs;
