import React from 'react';
import { Link } from 'gatsby';

const Categories = ({categories}) => {
  //console.log(categories);
  return (
    <aside className="widget widget_categories">
      <div className="widget-title">Categories</div>
      <ul>
        {categories.map(item => {
          return (<li key={item.category}><Link to={`/categories/${item.category}`}>{item.category}</Link> ({item.totalCount})</li>);
        })}
      </ul>
    </aside>
  );
};

export default Categories;