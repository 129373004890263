import React from 'react';
import { Link } from 'gatsby';

const Tags = ({tags}) => {
  //console.log(tags);
  return (
    <aside className="widget widget_tag_cloud">
      <div className="widget-title">Tags</div>
      <div className="tagcloud">
        {tags.map(item => {
          return (<Link to={`/tags/${item.tag}`} key={item.tag}>{` ${item.tag} (${item.totalCount})`}</Link>);
        })}
      </div>
    </aside>
  );
};

export default Tags;