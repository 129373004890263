import React from 'react';

import Navbar from './navbar';
import Sidebar from './sidebar';

const LayoutPost = ({ searchQuery, setSearchQuery, children, tags, categories, archives, hideSearch }) => {
  return (
    <div id="wrapper">
      <Navbar />
      <div className="page-wrapper">
        <section className="mt-5 pb-5">
          <div className="container">
            <div className="row pt-5">
              <div className="col-xl-8">
                {children}
              </div>

              <div className="col-xl-4">
                <Sidebar
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                  tags={tags}
                  categories={categories}
                  archives={archives}
                  hideSearch={hideSearch} />
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default LayoutPost
