import React from 'react';

const Search = ({ searchQuery, setSearchQuery }) => {

  return (
    <aside className="widget widget_search">
      <form autoComplete="off">
        <input
          className="form-control pr-5"
          type="search"
          placeholder="Search..."
          aria-label="Search"
          value={searchQuery}
          onInput={(e) => setSearchQuery(e.target.value)}
          name="search" />
        {/* <button className="search-button" type="submit" onSubmit={(e) => e.preventDefault()} aria-label="Submit"><span className="icon-search"></span></button> */}
      </form>
    </aside>
  );
}

export default Search;