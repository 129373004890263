import React from 'react';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

const SubscribeAndFollow = () => {

  return (
    <aside className="widget about-widget">
      <div className="widget-title">Subscribe & Follow</div>
      <ul className="socials">
        <li key="youtube"><OutboundLink href="https://www.youtube.com/c/K2VAcademy" target="_blank" rel="noreferrer" aria-label="YouTube"><i className="icon-youtube"></i></OutboundLink></li>
        <li key="facebook"><OutboundLink href="https://www.facebook.com/K2VAcademy" target="_blank" rel="noreferrer" aria-label="Facebook"><i className="icon-facebook"></i></OutboundLink></li>
        <li key="twitter"><OutboundLink href="https://twitter.com/K2vAcademy" target="_blank" rel="noreferrer" aria-label="Twitter"><i className="icon-twitter"></i></OutboundLink></li>
        <li key="linkedin"><OutboundLink href="https://www.linkedin.com/company/k2vacademy/" target="_blank" rel="noreferrer" aria-label="LinkedIn"><i className="icon-linkedin2"></i></OutboundLink></li>
      </ul>
    </aside>
  );
};

export default SubscribeAndFollow;