import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const PostPreviewSmall = ({ title, date, slug, image, isNext }) => {

  //console.log({ title, date, slug, image, isNext });

  return (
    <div className="col-sm-5">
      {isNext ? <p style={{marginBottom: '-20px', textAlign: 'right'}}>Next Post →</p> : <p style={{marginBottom: '-20px'}}>← Previous Post</p> }
      <article className="related-post">
        <div className="post-preview">
          <Link to={slug}>
            <GatsbyImage image={getImage(image)} alt={title} layout="fixed" formats={["AUTO", "WEBP"]} quality={80} className="img-fluid rounded" />
          </Link>
        </div>

        <div className="post-header">
          <h6>
            <Link to={slug}>{title}</Link>
          </h6>
          <p className="post-date">{date}</p>
        </div>
      </article>
    </div>
  );
};

export default PostPreviewSmall;