import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

const Bio = ({ author }) => {

  //console.log(author);

  return (
    <div className="media post-author-box">
      <GatsbyImage
        image={getImage(author.profile)}
        alt={author.name}
        className="d-flex mr-3 rounded-circle"
        layout="fixed"
        formats={["AUTO", "WEBP"]}
        width={78}
        height={78}
        quality={75} />
      <div className="media-body">
        <h4 className="media-heading"><Link to="/">{author.name}</Link></h4>
        <p className="mb-0">{author.description}</p>

        <ul className="socials list-unstyled mb-0 mt-3">
          <li key="facebook"><OutboundLink href={author.socials.facebook} target="_blank" rel="noreferrer" aria-label="Facebook Profile"><i className="icon-facebook"></i></OutboundLink></li>
          <li key="twitter"><OutboundLink href={author.socials.twitter} target="_blank" rel="noreferrer" aria-label="Twitter Profile"><i className="icon-twitter"></i></OutboundLink></li>
          <li key="github"><OutboundLink href={author.socials.github} target="_blank" rel="noreferrer" aria-label="GitHub Profile"><i className="icon-github"></i></OutboundLink></li>
          <li key="linkedin"><OutboundLink href={author.socials.linkedin} target="_blank" rel="noreferrer" aria-label="LinkedIn Profile"><i className="icon-linkedin2"></i></OutboundLink></li>
        </ul>
      </div>
    </div>
  );
}

export default Bio;
