import React from 'react';
import { Link } from 'gatsby';

const Archives = ({archives}) => {

  //console.log(archives);

  return (
    <aside className="widget">
      <div className="widget-title">Archives</div>
      <ul>
        {archives.map(item => {
          return (<li key={item.archive}><Link to={`/archives/${item.archive}`}>{item.archive}</Link> ({item.totalCount})</li>);
        })}
      </ul>
    </aside>
  );
};

export default Archives;