import React from 'react';
import { Link } from 'gatsby';

const PostTags = ({ tags }) => {
  //console.log(tags);
  return (
    <div className="mt-5">
      <h6>Tags:</h6>
      <div className="tagcloud">
        {tags.map((tag) => {
          return (<Link to={`/tags/${tag}`} key={tag}>{` ${tag} `}</Link>);
        })}
      </div>
    </div>
  );
};

export default PostTags;